import { useState } from "react";
import logo from "../../../assets/logo.png";
import closedMenu from "../../../assets/icons/closed-menu.svg";
import openedMenu from "../../../assets/icons/opened-menu.svg";

const menuItems = [
  { label: "Services", href: "#services" },
  { label: "Projects", href: "#projects" },
  { label: "Pricing", href: "#pricing" },
  { label: "Blog", href: "#blog" },
  { label: "FAQ", href: "#faq" },
];

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    console.log("Closing...");
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <nav className="sticky left-0 top-0 z-50 w-full bg-white bg-opacity-90 px-4 pt-4 sm:px-6 md:px-9 lg:px-12 xl:px-20 4xl:px-36">
      <div className="flex items-center justify-between font-light">
        <a href="/">
          <img
            src={logo}
            alt="logo"
            className="w-12 lg:w-16 xl:w-20 4xl:w-24"
          />
        </a>
        <div className="hidden space-x-10 xl:flex 2xl:text-xl 4xl:text-2xl">
          {menuItems.map(({ label, href }) => (
            <a key={href} href={href} className="hover:text-primaryViolet">
              {label}
            </a>
          ))}
        </div>
        <div className="hidden xl:flex">
          <a href="#contact">
            <button className="rounded-full bg-primaryViolet px-4 py-2 font-bold text-white transition duration-300 hover:bg-primaryGreen hover:text-primaryBlack">
              Contact me
            </button>
          </a>
        </div>
        <button
          className={`rounded-xl bg-primaryViolet p-1 focus:outline-none xl:hidden ${isMenuOpen ? "hidden" : "block"}`}
          onClick={toggleMenu}
          aria-label="Open menu"
        >
          <img src={closedMenu} className="w-8" alt="menu icon" />
        </button>
      </div>

      <div
        className={`overlay ${isMenuOpen ? "open" : ""} bg-primaryViolet bg-opacity-80 backdrop-blur-md`}
      >
        <div className="overlay-content flex w-full items-center justify-end px-4 pt-4 sm:px-6 md:px-9 lg:px-12 xl:px-20 4xl:px-36">
          <button
            className="z-10 rounded-xl bg-primaryGray p-1 xl:hidden"
            onClick={toggleMenu}
            aria-label="Close menu"
          >
            <img src={openedMenu} className="w-8" alt="close menu icon" />
          </button>
        </div>

        <div className="overlay-content -mt-14 flex h-full flex-col items-center justify-center space-y-12 text-2xl font-bold text-white">
          {menuItems.map(({ label, href }) => (
            <a key={href} href={href} onClick={toggleMenu}>
              {label}
            </a>
          ))}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
